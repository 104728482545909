import React, { useRef, useState } from "react"

// import { isEmpty } from "lodash"

import FORM_CONFIG, { SUCCESS_MSG } from "./config"
import Form from "./elements/Form"
import LanguageSelect from "./elements/LanguageSelect"
import PhoneInput from "./elements/PhoneInput"
import Recaptcha from "./elements/Recaptcha"
import TermsConditions from "./elements/TermsConditions"
import TextInput from "./elements/TextInput"
import { cpSchema } from "./schemas"
import {
  connectOptions,
  formTracking,
  generateUuid,
  getBitrixData,
  isProduction,
  languagesOptions,
  postFormData,
  sendMail,
  useBlock,
} from "./utils"
import useEmailContent from "@hooks/dataHooks/useEmailContent"
import TextAreaInput from "./elements/TextAreaInput"
import Select from "./elements/Select"
import { useGlobalStore } from "@stores/globalStore"
const CPForm = () => {
  const recaptchaRef = useRef()
  const data = useEmailContent()
  const { ipAddress, setgaEmail, setShowSuccessMsg } = useGlobalStore()
  const formConfig = FORM_CONFIG.complaintsProcedure
  let emailContentTemp = data?.filter((item) => item.choose_template ===  formConfig?.emailTempUser);
  const emailContent = emailContentTemp[0]?.content?.data?.content;
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (data) => {
    setIsLoading(true); // Start loading
        
    const ipList = await useBlock();
    const isBlocked = ipList?.ip?.some(item => item?.add_ip_address === ipAddress);
    const isBlocked1 = ipList?.email?.some(item => item?.email === data.email);
    setgaEmail(data?.email)
   
    const token = await recaptchaRef.current?.executeAsync()

    if (token) {
      const unique_id = generateUuid()
      const postData = {
        first_name: data.name,
        url: window.location.href,
        unique_id: unique_id,
        "g-recaptcha-response": token,
        ...data,
        ...formConfig,
        google_data:{
          form_name:formConfig?.form_name,
          name:data?.name,
          email:data?.email,
          message:data?.message,
          language: languagesOptions.find((item) => item.value === data.language)?.label ||
          data.language,
          mode_of_contact:connectOptions.find(
            (item) => item.value === data.cemail
          )?.label || data.cemail,
          telephone:data?.telephone
      },
        extra: { formConfig, data },
      }

      const bitrixData = getBitrixData(postData)

      const formData = new FormData()

      formData.append("data", JSON.stringify(bitrixData))

      recaptchaRef.current?.reset()
      if(isBlocked || isBlocked1){
        setIsLoading(false); // End loading
        return;
      }
      else {
        try {
          setTimeout(() => {
            setShowSuccessMsg(true)
          }, 1000)
          postFormData(formData);
          sendMail(bitrixData);
          formTracking({
            unique_id: unique_id,
            eventTracking: formConfig.eventTracking,
            formName: formConfig.form_name,
            email: data?.email,
            phone: data?.telephone
          })
        } catch (error) {
          setIsLoading(false); // End loading
          console.error("Error submitting form:", error);
        } finally {
          setIsLoading(false); // End loading
        }
      
    }
  }

  }
  return (
    <Form
      className="contact-form"
      validationSchema={cpSchema}
      onSubmit={onSubmit}
      successMsg={SUCCESS_MSG}
    >
      <div className="form-grid">
        <TextInput name="name" placeholder="Name" label="Full Name*" />
        <TextInput
          name="email"
          placeholder="Email"
          type="email"
          label="Email Address*"
        />
        <PhoneInput name="telephone" label="Phone*" />
        <LanguageSelect />
        <Select
            options={connectOptions}
            name="cemail"
            label="How would you like to be contacted about your complaint?: *"
            // defaultValue={connectOptions[0].value}
          />
        <TextAreaInput
          name="message"
          placeholder="Write your message here..."
          label="Can you tell us what happened? *"
        />
        <div className="d-none"><TextInput name="emailContent" type="hidden" value={emailContent} /></div>
      </div>
      <div className="form-bottom">
        <button className="button button-orange" type="submit">
          <span>{isLoading ? "Loading..." : "Submit Details"}</span>
        </button>
        <TermsConditions />
      </div>
      <Recaptcha recaptchaRef={recaptchaRef} />
    </Form>
  )
}

export default CPForm
